import gql from 'graphql-tag'

export const QUERY_GET_CLIENT_UNITS_BY_CLIENT = gql`
  query clientUnits($client_units: ClientUnitsInput!) {
    clientUnits(client_units: $client_units) {
      _id
      name
      cnpj
      status
      corporate_name
      statual_insc
      municipal_insc
      segments
      total_pdv_amount
      total_pdv_contracted
      store_main
      location {
        type
        coordinates
      }
      address
      address_components {
        postal_code
        street
        neighborhood
        number
        complement
        city
        city_name
        federative_unit
      }
      account_units {
        account_id
        unit_id
      }
      cbm
      mercapromo
      mercashop
      promotrade
      updated_at
      created_at
      is_test
    }
  }
`
export const MUTATION_ADD_CLIENT_UNIT = gql`
  mutation InsertClientUnit($insert_client_unit: CreateClientUnitInput!) {
    insertClientUnit(insert_client_unit: $insert_client_unit) {
      message
      success
    }
  }
`

export const MUTATION_UPDATE_CLIENT_UNIT = gql`
  mutation UpdateClientUnit($update_client_unit: UpdateClientUnitInput!) {
    updateClientUnit(update_client_unit: $update_client_unit) {
      message
      success
    }
  }
`

export const MUTATION_DELETE_CLIENT_UNIT = gql`
  mutation DeleteClientUnit($delete_client_unit: DeleteClientUnitInput!) {
    deleteClientUnit(delete_client_unit: $delete_client_unit) {
      message
      success
    }
  }
`
