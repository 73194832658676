import gql from 'graphql-tag'

export const QUERY_GET_AVAILABLE_PRODUCTS = gql`
  query availableProducts($client_id: String!, $filters: AvailableProductFilters!, $pagination: PaginationParams!) {
    availableProducts(client_id: $client_id, filters: $filters, pagination: $pagination) {
      data {
        _id
        active
        created_at
        id_client
        product_type
        total_mrr
        updated_at
        slots
        index
        sale_id
        unit_value
        billing_date
        sale_date
        client_units {
          _id
          name
          cnpj
        }
        temporary_churn {
          temporary_churn_period {
            start_date
            end_date
          }
          aboned_quantity
          aboned_value
        }
      }
      count
    }
  }
`

export const QUERY_GET_AVAILABLE_PRODUCTS_COUNT = gql`
  query availableProducts($client_id: String!, $filters: AvailableProductFilters!, $pagination: PaginationParams!) {
    availableProducts(client_id: $client_id, filters: $filters, pagination: $pagination) {
      data {
        active
      }
      count
    }
  }
`

export const SET_PRODUCT_BILLING_DATE = gql`
  mutation setProductBillingDate($available_product_id: String!, $billing_date: DateTime!) {
    setProductBillingDate(available_product_id: $available_product_id, billing_date: $billing_date) {
      message
      success
    }
  }
`
