var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loading-dialog',{attrs:{"loading":_vm.$apollo.loading}},[_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Compartilhamento de dados com Indústrias")]),_c('v-row',{staticClass:"pl-4"},_vm._l((_vm.options),function(option){return _c('v-col',{key:option.id,staticClass:"my-n1 py-0",attrs:{"cols":"4"}},[_c('v-checkbox',{attrs:{"label":option.label,"dense":""},model:{value:(option.checked),callback:function ($$v) {_vm.$set(option, "checked", $$v)},expression:"option.checked"}})],1)}),1),_c('mf-action-buttons',{staticClass:"pa-4 mr-2",attrs:{"primary-button":{
        text: 'Salvar',
        action: _vm.save,
        isVisible: true,
        isDisabled: false,
        isLoading: _vm.loading
      },"cancel-button":{
        text: 'Cancelar',
        action: function () { return _vm.$router.push('/retailers'); },
        isVisible: true,
        isDisabled: false
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }