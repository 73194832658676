<template>
  <loading-dialog :loading="$apollo.loading">
    <v-card class="mt-3" flat>
      <v-card-title>Compartilhamento de dados com Indústrias</v-card-title>
      <v-row class="pl-4">
        <v-col v-for="option in options" :key="option.id" cols="4" class="my-n1 py-0">
          <v-checkbox v-model="option.checked" :label="option.label" dense />
        </v-col>
      </v-row>

      <mf-action-buttons
        class="pa-4 mr-2"
        :primary-button="{
          text: 'Salvar',
          action: save,
          isVisible: true,
          isDisabled: false,
          isLoading: loading
        }"
        :cancel-button="{
          text: 'Cancelar',
          action: () => $router.push('/retailers'),
          isVisible: true,
          isDisabled: false
        }"
      />
    </v-card>
  </loading-dialog>
</template>

<script>
import { MUTATION_UPDATE_CLIENT_INDUSTRY_INFORMATION_SHARING } from '@/modules/retailers/graphql'

export default {
  components: {
    LoadingDialog: () => import('@/components/base/LoadingDialog.vue')
  },
  props: {
    clientId: {
      type: String,
      default: () => ''
    }
  },

  data() {
    return {
      loading: false,
      options: [
        { id: 1, label: 'Promoções executadas', value: 'executed_promos', checked: false },
        { id: 2, label: 'Período comparativo para promoções executadas', value: 'reference_periods', checked: false },
        { id: 3, label: 'Sellout dos produtos da indústria', value: 'products_sellout', checked: false },
        { id: 4, label: 'Sellout da categoria dos produtos selecionados', value: 'category_sellout', checked: false },
        { id: 5, label: 'Estoque dos produtos da indústria', value: 'products_stock', checked: false },
        { id: 6, label: 'Identificação anonimizada de consumidores', value: 'consumer_anonimized_identification', checked: false }
      ]
    }
  },
  computed: {
    saveOptin() {
      const result = this.options.reduce((acc, option) => {
        acc[option.value] = option.checked
        return acc
      }, {})
      return result
    }
  },
  methods: {
    async save() {
      this.loading = true

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_CLIENT_INDUSTRY_INFORMATION_SHARING,
          variables: {
            client_id: this.clientId,
            sharing_options: this.saveOptin
          }
        })

        this.$snackbar({ message: 'Opções de compartilhamento salvas com sucesso', snackbarColor: '#2E7D32' })
      } catch (e) {
        this.$snackbar({ message: 'Falha ao salvar opções de compartilhamento', snackbarColor: '#F44336' })
      }

      this.loading = false
    }
  }
}
</script>
