import gql from 'graphql-tag'

export const QUERY_GET_ORIGINS = gql`
  query {
    origins {
      created_at
      name
      active
      deprecated_sub_origins
      sub_origins
      type
      value
    }
  }
`
