import gql from 'graphql-tag'

export const QUERY_GET_SALES = gql`
  query GetSales($client_id: String!, $filters: SaleSearchFilter, $pagination: PaginationParams!) {
    sales(client_id: $client_id, filters: $filters, pagination: $pagination) {
      data {
        _id
        created_at
        id_client
        contract_link
        origin
        sub_origin
        items {
          charge_type
          downsell {
            reason
            type
            unit_id
          }
          implantation_value
          is_accumulative
          name
          total_value
          type
          unit_value
          quantity
          sale_id
          index
          old_total_value
          old_unit_value
          discount_operation_value
        }
        observation
        owner
        type
        billing_date
      }
      count
    }
  }
`
export const MUTATION_INSERT_SALE = gql`
  mutation RegisterSale($client_id: String!, $sale: SaleInput!) {
    registerSale(client_id: $client_id, sale: $sale) {
      message
      success
    }
  }
`
export const QUERY_GET_SALE_BY_ID = gql`
  query GetSale($sale_id: String!) {
    sale(sale_id: $sale_id) {
      _id
      created_at
      id_client
      items {
        charge_type
        downsell {
          reason
          type
          unit_id
        }
        implantation_value
        is_accumulative
        name
        total_value
        type
        unit_value
        quantity
        sale_id
        index
        billing_date_periods
        immediate_billing
        plan
        old_total_value
        old_unit_value
        discount_operation_value
        units
      }
      owner
      type
      temporary_churn_period {
        start_date
        end_date
      }
      temporary_churn_reverted
      origin
      sub_origin
      is_ambev_participation
      ambev_incentive {
        is_ambev_incentive
        ambev_incentive_months
        ambev_incentive_text
      }
    }
  }
`
