import gql from 'graphql-tag'

export const QUERY_GET_COORDINATES = gql`
  mutation Geocode($geocode_input: GeocodeInput!) {
    geocode(geocodeInput: $geocode_input) {
      hashId
      metaInformation {
        origin
        providerName
      }
      address {
        street
        streetId
        number
        neighborhood
        neighborhoodId
        city
        cityId
        state
        stateCode
        stateId
        country
        countryCode
        countryId
        postalCode
        lat
        lng
        formattedAddress
      }
      errors {
        errorType
        message
        providerErrors {
          providerName
          message
        }
      }
    }
  }
`
