import gql from 'graphql-tag'

export const MUTATION_DELETE_RETAILER = gql`
  mutation deleteClient($client_id: String!) {
    deleteClient(client_id: $client_id) {
      message
      success
    }
  }
`

export const QUERY_GET_RETAILERS_PAGINATED = gql`
  query clients($filters: ClientFilters!, $pagination: PaginationParams!) {
    clients(filters: $filters, pagination: $pagination) {
      data {
        _id
        id_client
        active
        classification_size
        cnpj
        revenue {
          recurrent {
            total
          }
        }
        contacts {
          owner
          role
          email
          phone
        }
        corporate_name
        created_at
        erp_backoffice {
          _id
          name
        }
        erp_pdv {
          _id
          name
        }
        fantasy_name
        franchise {
          _id
          fantasy_name
        }
        id_exact_sales
        origin
        segment
        sub_origin
        total_stores
        status
        churn {
          cause
          cause_details
          details
          phase
          reason
          inactivated_at
          billed_client
        }
        client_units {
          _id
          name
        }
      }
      count
    }
  }
`

export const QUERY_ALL_RETAILERS_SIMPLE = gql`
  query clients {
    clients(filters: {}, pagination: { page: 1, pageSize: 99999, sort: ["_id"], sortDirection: ASC }) {
      data {
        _id
        id_client
        fantasy_name
        cnpj
      }
    }
  }
`

export const MUTATION_UPDATE_RETAILER = gql`
  mutation updateClient($client_id: String!, $client: ClientUpdate!) {
    updateClient(client_id: $client_id, update: $client) {
      message
      success
    }
  }
`

export const QUERY_GET_RETAILER = gql`
  query client($client_id: String!) {
    client(client_id: $client_id) {
      _id
      id_client
      active
      status
      accounts
      classification_size
      cnpj
      revenue {
        recurrent {
          total
          per_product {
            product_type
            value
          }
        }
        recurrent_waived {
          total
          per_product {
            product_type
            value
          }
        }
        onetime {
          total
          per_product {
            product_type
            value
          }
        }
      }
      contacts {
        owner
        role
        email
        phone
      }
      address {
        federative_unit
      }
      corporate_name
      created_at
      erp_backoffice {
        _id
        name
      }
      erp_pdv {
        _id
        name
      }
      fantasy_name
      franchise {
        _id
        fantasy_name
      }
      id_exact_sales
      origin
      segment
      sub_origin
      total_stores
      client_units {
        _id
        name
      }
      sheet_links {
        attributes
      }
      is_test
    }
  }
`

export const MUTATION_CREATE_RETAILER = gql`
  mutation registerClient($client: ClientInput!) {
    registerClient(client: $client) {
      _id
    }
  }
`

export const MUTATION_SET_CHURN = gql`
  mutation setChurn($client_id: String!, $data: ClientChurnInput!) {
    setChurn(churn_options: $data, client_id: $client_id) {
      message
      success
    }
  }
`
export const MUTATION_UNSET_CHURN = gql`
  mutation unsetChurn($client_id: String!) {
    unsetChurn(client_id: $client_id) {
      message
      success
    }
  }
`
export const QUERY_GET_AVAILABLE_ACCOUNTS = gql`
  query availableAccounts {
    availableAccounts {
      _id
      name
    }
  }
`

export const QUERY_SEARCH_CNPJ = gql`
  query searchCnpj($search: String!) {
    searchCnpj(search: $search) {
      _id
      cnpj
    }
  }
`

export const QUERY_GET_HISTORY_CHURN = gql`
  query getHistoryChurn($client_id: String!) {
    getHistoryChurn(client_id: $client_id) {
      billed_client
      cause
      cause_details
      details
      inactivated_at
      inactivated_by
      mode
      phase
      reason
      recovered_at
      recovered_by
      responsible_area
    }
  }
`

export const MUTATION_CREATE_CLIENT_SHEET = gql`
  mutation createClientSheet($client_id: String!, $sheet_template_type: SheetTemplateType!) {
    createClientSheet(client_id: $client_id, sheet_template_type: $sheet_template_type)
  }
`
export const MUTATION_UPDATE_CLIENT_INDUSTRY_INFORMATION_SHARING = gql`
  mutation editClientIndustryInformationSharing($client_id: String!, $sharing_options: IndustryInformationSharingInput!) {
    industryInformationSharing(client_id: $client_id, sharing_options: $sharing_options)
  }
`
